/* Icono */

.rm-icon {
  width: 25px;
  height: 25px;
  margin: 3px;
  cursor: pointer;
}




.sugerencia{
  padding: 5px;
}


.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  /* top: 31px; */
  /* width: 500px; */
  color: var(--bs-emphasis-color);
  border: 1px solid #aaa;
  background-color: var(--bs-body-bg);
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  /* max-height: 300px; */
  overflow: auto;
  margin-top: 5px;
  /* left: 0;
  right: 0; */
  margin-left: auto;
  margin-right: auto;
}

.react-autosuggest__suggestions-list{
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
  cursor: pointer;
}

.react-autosuggest__input{
  width: 100%;
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--bs-secondary-bg);
}