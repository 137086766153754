.tarea-contenedor {
  width: 100%;
  margin: 5px 0;
  padding: 8px 15px 8px 20px;
  justify-content: space-between;
  border-radius: 7px;
  border: 1px solid #a7c7d1;
  background-color: lightblue;
}

.tarea-header {
  display: flex;
}

/* Colores */

/* .tarea-contenedor:nth-child(3n + 1) {
  background-color: #1b1b32;
}

.tarea-contenedor:nth-child(3n + 2) {
  background-color: #2a2a40;
}

.tarea-contenedor:nth-child(3n + 3) {
  background-color: #3b3b4f;
} */

/* .tarea-contenedor {
  background-color: #150c5a;
} */


/* Texto */

.tarea-texto {
  width: 100%;
  height: 100%;
  font-size: 15px;
  align-items: center;
  overflow-wrap: anywhere;
}

.tarea-texto h2{
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-wrap: anywhere;
}

/* Icono */

.tarea-icono {
  width: 25px;
  height: 25px;
  margin: 5px;
  cursor: pointer;
}

/* Tarea Completada */

.tarea-contenedor.completada {
  background-color: #5a01a7;
  text-decoration: line-through;
}

a {
  /* color: white; */
  text-decoration: none;
}